import md5 from 'js-md5'
export var SearchList = [
  { labe: 'FCS单号', code: 'code', type: 'input' },
  {
    labe: '创建日期',
    code: 'tempCreateDate',
    type: 'daterange'
  },
  {
    labe: '状态',
    code: 'status',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: '待采购确认', value: '1' },
      { label: '待我确认', value: '2' },
      { label: '已完成', value: '3' }
    ]
  },
  {
    labe: '周期',
    code: 'cycle',
    type: 'input'
  },
  { labe: '物料编号', code: 'materialCode', type: 'input' }
]
export var SearchData = {
  code: '',
  startTime: '',
  endTime: '',
  status: '',
  cycle: '',
  materialCode: ''
}
export var tableField = [
  { selectType: 'selection', type: 'input', width: '' },
  { label: 'FCS单号', code: 'code', type: 'input', width: '' },
  { label: '物料编号', code: 'materialCode', type: 'input', width: '' },
  { label: '物料描述', code: 'materialDesc', type: 'input', width: '' },
  { label: '周期', code: 'cycle', type: 'input', width: '' },
  { label: '数量', code: 'materialNum', type: 'input', width: '' },
  { label: '修正数量', code: 'updateNum', type: 'input', width: '' },
  {
    label: '反馈数量',
    code: 'feedbackNum',
    width: '',
    type: 'vnode',
    handle: function (scope) {
      if (scope) {
        const parentNode = this.$parent.$parent
        if (scope.row.status === 4) {
          scope.row.judgeEdit = 2
        }
        return {
          data: function () {
            return {
              rowData: { ...scope.row }
            }
          },
          created () {
            parentNode.rowChildren[scope.$index] = this
          },
          methods: {
            clk: function () {
            }
          },
          template: `<span>
            <span v-if="rowData.judgeEdit!='2'">{{rowData.feedbackNum}}</span>
            <el-input v-if="rowData.judgeEdit=='2'" v-model="rowData.feedbackNum" placeholder="请输入数量"></el-input>
          </span>`
        }
      }
    }
  },
  /*   { label: '反馈数量', code: 'feedbackNum', type: 'input', width: '' }, */
  { label: '采购员', code: 'createUserName', type: 'input', width: '' },
  { label: '创建日期', code: 'createDate', type: 'input', width: '' },
  {
    label: '状态',
    code: 'status',
    type: 'function',
    width: '',
    handle: (index, data) => {
      const color = md5(data.status + '').substring(0, 6)
      const label = data.status === 1 ? '待采购确认' : data.status === 2 ? '待我确认' : '已完成'
      const tblab = `<div class="dotlab"><div class="dot" style="background:#${color}"></div>${label}</div>`
      return tblab
    }
  },
  {
    label: '操作',
    code: 'act',
    width: '',
    type: 'action',
    actionData: [
      {
        actionLabel: '编辑',
        id: '1',
        stateField: 'judgeEdit',
        stateSymbol: '!=',
        stateValue: '2'

      },
      {
        actionLabel: '确认',
        id: '2',
        stateField: 'judgeEdit',
        stateSymbol: '!=',
        stateValue: '2'
      },
      {
        actionLabel: '保存',
        id: '3',
        stateField: 'judgeEdit',
        stateSymbol: '==',
        stateValue: '2'
      },
      {
        actionLabel: '取消',
        id: '4',
        stateField: 'judgeEdit',
        stateSymbol: '==',
        stateValue: '2'
      }
    ]
  }
]

export var TableData = {
  buyer: '',
  code: '',
  createDate: '',
  createUserId: '',
  createUserName: '',
  cycle: '',
  materialCode: '',
  materialDesc: '',
  materialNum: 0,
  modifyDate: '',
  status: 0,
  supplierCode: '',
  supplierName: '',
  updateNum: 0
}
